import { environment } from './environments/environment';
import { LicenseManager } from 'ag-grid-enterprise';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  LicenseManager.setLicenseKey(environment.agGridLicenseKey);
  if (environment.production) {
    enableProdMode();
    window.console.log = () => { }
  }
