<div id="notfound">
  <div class="notfound">
  <div class="notfound-404">
  <h1>Access Denied</h1>
  </div>
  <h2>We are sorry</h2>
  <p>You are unauthorized to access this content. Please contact your local administrator for more help.</p>
  <a (click) = "logOut()" >Logout</a>
  </div>
  </div>
