<div class="mat-mdc-dialog-content">
  <p>
    Updates have taken place on the Algo solution.  Please click "OK" below to make sure you have the best Algo experience.
  </p>
</div>
<div class="action-panel text-right btn-wrapper">
  <div>
    <button  class="btn btn-primary m-right" color="warn" (click)="confirm()">
    OK
    </button>
  </div>
</div>
