import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-version-update',
  templateUrl: './version-update.component.html',
  styleUrls: ['./version-update.component.css']
})
export class VersionUpdateComponent implements OnInit {

  constructor(public dialogRef: DynamicDialogRef) { }

  ngOnInit() {
  }
  confirm() {
    this.dialogRef.close(true);
  }
}
