import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { environment } from '../environments/environment';
import { AppMasterGuard } from '../guards/app-master.guard';
import { UnauthorizedAccessComponent } from '../modules/auth/features/unauthorized-access/unauthorized-access.component';

const CALLBACK_PATH = 'login/callback';
const routes: Routes = [
  { path: CALLBACK_PATH, component: OktaCallbackComponent },
  {
    path: '',
    loadChildren: () => import('../modules/layout/layout.module').then(m => m.LayoutModule)
  },
  { path: '_admin', loadChildren: () => import('../modules/auth/auth.module').then(m => m.AuthModule)},
  { path: 'access-denied', component: UnauthorizedAccessComponent },
  // { path: 'signout', component: LogoutComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
